/* eslint-disable operator-linebreak */
export class SvgMoveAnimation {
  constructor(options) {
    this.configPoints = options.configPoints
    this.animateTimeout = 2500
    this.resetCircleTimeout = 3000
    this.startCurrentIndex = options.startCurrentIndex || 0
    this.resetDistance = options.resetDistance
    this.$rootEl = options.rootEl
    this.$svgMoveItem = this.$rootEl.find('.js-svgMoveItem')
    this.$svgCircles = $(`.${this.$rootEl.data('root-circles')}`)

    this.initHandlers()
  }

  initHandlers() {
    this.$svgMoveItem.on('click', (e) => {
      const currentIndex = $(e.currentTarget).index()
      const currentShift = this.configPoints[currentIndex]

      $('circle', this.$svgCircles).each((index, el) => {
        const transition =
          currentIndex < 2
            ? `${2000 - index * 150}ms`
            : `${2000 + index * 150}ms`

        $(el).css({
          transition,
          'offset-distance': `${currentShift[index].distance}%`,
          r: currentShift[index].r,
        })
      })

      this.$svgMoveItem.addClass('is_hidden')

      setTimeout(() => {
        this.moveItems($(e.currentTarget))
        this.$svgMoveItem.removeClass('is_hidden')
      }, this.animateTimeout)

      setTimeout(() => {
        this.resetCirclePoints()
      }, this.resetCircleTimeout)
    })
  }

  resetCirclePoints() {
    const $fields = $('circle', this.$svgCircles)
    $fields.each((index, el) => {
      const currentDistance = this.resetDistance[index].distance
      const currentRadius = this.resetDistance[index].r

      $(el).css({
        transition: 'none',
        'offset-distance': `${currentDistance}%`,
        r: currentRadius,
      })
    })
  }

  moveItems(currentTarget) {
    const currentIndex = currentTarget.index()
    const item = this.$rootEl.find('.js-svgMoveItem')
    const parent = item.parent()

    if (
      this.startCurrentIndex === 0 &&
      this.startCurrentIndex === currentIndex
    ) {
      parent.append(currentTarget)
      return
    }

    if (
      this.startCurrentIndex === 1 &&
      this.startCurrentIndex === currentIndex
    ) {
      parent.append(item.first())
      return
    }

    parent.prepend(item.last())
  }
}
