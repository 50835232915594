$('.js-asidePanelButton').on('click', (e) => {
  if (e.currentTarget === 'a') {
    e.preventDefault()
  }

  const currentPanel = $(
    `[data-aside-panel-id="${$(e.currentTarget).attr('data-aside-panel')}"]`,
  )

  currentPanel.addClass('is_opened')

  $('body').addClass('body-lock')
})

$('.js-asidePanelClose').on('click', () => {
  $('.aside-panel').removeClass('is_opened')

  $('body').removeClass('body-lock')
})
