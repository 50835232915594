$('.js-orderCartToggle').on('click', (e) => {
  e.preventDefault()
  $('.js-orderCartLayer').addClass('is_visible')
  $('body').addClass('body-lock')
})

$('.js-orderCartClose').on('click', () => {
  $('.js-orderCartLayer').removeClass('is_visible')
  $('body').removeClass('body-lock')
})
