import $ from 'jquery'
import Mustache from 'mustache'
import Inputmask from 'inputmask'

export default class HeaderBasket {
  constructor ($el, basketManager, app) {
    this.DOM = {}
    this.DOM.$el = $el
    this.app = app
    this.isAjaxRunning = false
    this.basketManager = basketManager

    this.init()
  }

  init()
  {
    this.cacheSelectors()
    this.render()
    this.bindEvents()
  }

  cacheSelectors() {
    this.DOM.$orderForm = this.DOM.$el.find('.js-checkout-form')
    this.DOM.$basketItems = this.DOM.$el.find('.js-checkout-basket-items')
    this.DOM.$basketTotal = this.DOM.$el.find('.js-checkout-basket-total')
    this.DOM.$delivery = this.DOM.$el.find('.js-order-delivery')
    this.DOM.$contact = this.DOM.$el.find('.js-order-contact')
    this.DOM.$confirm = this.DOM.$el.find('.js-order-confirm')
    this.DOM.$submitButton = this.DOM.$el.find('.js-order-submit-button')

    this.basketItemsTemplate = this.DOM.$el
      .find('.js-checkout-basket-items-template')
      .html()
    this.basketTotalTemplate = this.DOM.$el
      .find('.js-checkout-basket-total-template')
      .html()
    this.deliveryTemplate = this.DOM.$el
      .find('.js-order-delivery-template')
      .html()
    this.contactTemplate = this.DOM.$el
      .find('.js-order-contact-template')
      .html()
    this.confirmTemplate = this.DOM.$el
      .find('.js-order-confirm-template')
      .html()

    this.data = $.parseJSON(this.DOM.$el.find('.js-data').html())
    this.errors = $.parseJSON(this.DOM.$el.find('.js-errors').html())

    this.basketManager.setData(this.data)
  }

  render() {
    // this.redirects()
    this.renderDelivery()
    this.renderContact()
    this.renderBasket()
    this.renderSummary()
    // this.renderSubmitButton()
    // //this.renderPaySystem()
    this.renderConfirm()
    this.createComponents()

    this.DOM.$el.find('.js-total-count').val(this.data.SUMMARY.COUNT)
  }

  createComponents() {
    const im = new Inputmask({
      mask: '+7 (999) 999-99-99',
      placeholder: '_',
      showMaskOnHover: true,
    })

    im.mask($('.js-phone'))

    /*this.DOM.$orderForm.validate({
      submitHandler: (form, e) => {
        e.preventDefault()

        this.sendRequest(this.DOM.$orderForm, [{ name: 'save', value: true }])
      }
    })*/
  }

  renderBasket() {
    this.DOM.$basketItems.html(
      Mustache.render(this.basketItemsTemplate, this.data.BASKET)
    )
  }

  renderSummary() {
    this.DOM.$basketTotal.html(
      Mustache.render(this.basketTotalTemplate, this.data.SUMMARY)
    )
  }

  renderDelivery() {
    this.DOM.$delivery.html(
      Mustache.render(this.deliveryTemplate, this.data)
    )
  }

  renderContact() {
    this.DOM.$contact.html(
      Mustache.render(this.contactTemplate, this.data.CONTACT)
    )
  }

  renderConfirm() {
    this.DOM.$confirm.html(
      Mustache.render(this.confirmTemplate, this.data)
    )
  }

  bindEvents() {
    this.DOM.$submitButton.on('click', e => {
      e.preventDefault()
      if (this.DOM.$orderForm.valid()) {
        this.sendRequest(this.DOM.$orderForm, [{ name: 'save', value: true }])
      }
      /*$(e.currentTarget)
        .closest('form')
        .submit()*/
      //this.sendRequest(this.DOM.$orderForm, [{ name: 'save', value: true }])
    })
  }

  sendRequest (form, additionalData = [], onSuccess = function () {}, onError = function (errors) {}, url = '') {
    if (this.isAjaxRunning) {
      return
    }

    this.ajaxStarted()

    let orderData = this.DOM.$orderForm.serializeArray()
    orderData.push({
      name: 'sessid',
      value: window.app.sessid
    })

    if (form && !this.DOM.$orderForm.is(form)) {

      const data = form.serializeArray()
      orderData = orderData.concat(data)
    }

    if (additionalData.length > 0) {
      orderData = orderData.concat(additionalData)
    }
    console.log(orderData)
    this.sendAjax(form, orderData, onSuccess, onError, url)

    /*if (typeof window.recaptchaGetToken === 'function')
    {
      let _this = this;
      window.recaptchaGetToken().then(function(token) {
        orderData.push({
          name: window.recaptchaInput,
          value: token
        });
        _this.sendAjax(form, orderData, onSuccess, onError, url)
      });
    } else {
      this.sendAjax(form, orderData, onSuccess, onError, url)
    }*/
  }

  sendAjax(form, orderData, onSuccess = function (data) {}, onError = function (errors) {}, url = '') {
    let urlSend = $(form).attr('action')

    if (url) {
      urlSend = url
    }

    $.ajax({
      url: urlSend,
      method: $(form).attr('method'),
      data: orderData
    }).then(response => {
      if (response.data) {
        let errors = response.errors
        if (errors.length > 0) {
          this.data.SUMMARY.HAS_WARNINGS = response.data.SUMMARY.HAS_WARNINGS
          this.data.SUMMARY.WARNINGS = response.data.SUMMARY.WARNINGS
        } else {
          this.data = response.data
        }
        console.log(this.data)
        this.redirects()
        this.basketManager.refresh(this.data)
      }

      if (response.status === 'success') {
        onSuccess(this.data)
      } else if (response.errors) {
       // this.showErrors(response.errors)
        onError(response.errors)
      }

      this.ajaxFinished()
    })
  }

  ajaxStarted () {
    this.isAjaxRunning = true
  }

  ajaxFinished () {
    this.isAjaxRunning = false
  }

  redirects () {
    if (this.data.CREATED_URL) {
      window.location.href = this.data.CREATED_URL
    }

    if (this.data.SUMMARY && this.data.SUMMARY.COUNT <= 0) {
      window.location.reload()
    }
  }

  refresh () {
    this.sendRequest(this.DOM.$orderForm, [], () => {})
  }
}
