import '../vendor/jquery-validation'

if ($('html').attr('lang') === 'ru') {
  $.extend($.validator.messages, {
    required: 'Это поле обязательно для заполнения.',
    remote: 'Пожалуйста, введите правильное значение.',
    email: 'Указан неверный формат e-mail адреса.',
    url: 'Пожалуйста, введите корректный URL.',
    date: 'Пожалуйста, введите корректную дату.',
    dateISO: 'Пожалуйста, введите корректную дату в формате ISO.',
    number: 'Пожалуйста, введите число.',
    digits: 'Пожалуйста, вводите только цифры.',
    creditcard: 'Пожалуйста, введите правильный номер кредитной карты.',
    equalTo: 'Пожалуйста, введите такое же значение ещё раз.',
    extension: 'Пожалуйста, выберите файл с правильным расширением.',
    maxlength: $.validator.format(
      'Максимальная длина описания - {0} символов.',
    ),
    minlength: $.validator.format(
      'Пожалуйста, введите не меньше {0} символов.',
    ),
    rangelength: $.validator.format(
      'Пожалуйста, введите значение длиной от {0} до {1} символов.',
    ),
    range: $.validator.format('Пожалуйста, введите число от {0} до {1}.'),
    max: $.validator.format('Максимальное число - {0}.'),
    min: $.validator.format(
      'Пожалуйста, введите число, большее или равное {0}.',
    ),
  })
}

$('.js-validate').each((_, form) => {
  $(form).validate({
    errorElement: 'span',
    errorClass: 'label-error',
    validClass: 'is-success',
    focusInvalid: false,
    onfocusout(element) {
      this.element(element)
    },
    invalidHandler(_invalid, validator) {
      if (!validator.numberOfInvalids()) return

      validator.errorList[0].element.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      })

      validator.errorList[0].element.focus({ preventScroll: true })
    },
    // errorPlacement() {},
    highlight(element, _errorClass, validClass) {
      $(element).parent().addClass('is-error').removeClass(validClass)
    },
    unhighlight(element, _errorClass, validClass) {
      $(element).parent().removeClass('is-error')

      if ($(element).val().length) {
        $(element).parent().addClass(validClass)
      }
    },
  })
})
