$('.js-headerSearchControl').on('click', (e) => {
  e.preventDefault()
  $('.header-search').toggleClass('header-search_is_visible')

  if ($('.header-search').hasClass('header-search_is_visible')) {
    setTimeout(() => {
      $('.header-search .form-input').focus()
    }, 500)
  }
})
