$('.js-personalAppealRadio').on('change', (e) => {
  const currentTarget = $(e.currentTarget)

  $('.accordion__title').text(
    currentTarget.parent().find('.personal-appeal-radios-label__text').text(),
  )

  currentTarget
    .parents('.accordion')
    .find('.js-accordionToggle')
    .trigger('click')
})
