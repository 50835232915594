import './preloader'
import Catalog from './catalog'
import './tab'
import './menu'
import './cart-aside'
import './validate'
import './product'
import './footer'
import './header'
import './order-group'
import './order-products'
import CatalogProduct from "./catalogproduct"
import './catalog-filter'
import './catalog-entering/index'
import './home'
import './read-more'
import './SvgMoveAnimation'
import './auth'
import './catalog-size-slider'
import './catalog-mob'
import './scroll'
import './accordion'
import './personal'
import './aside-panel'
import './map/map'
import './order-cart'
import BasketManager from './basketmanager'
import HeaderBasket from './headerbasket'
import Num from './num'
import Checkout from './checkout'
import './mask'

export default class App {
  constructor() {
    this.DOM = {}
    this.sessid = $('meta[name="csrf-token"]').attr('content') || ''

    this.init()
  }

  init() {
    this.createComponents()
  }

  createComponents () {
    this.initBasketManager()
    this.initHeaderBasket()
    this.initCatalog()
    this.initCatalogProduct()
    this.initCheckout()
    this.initNum()
  }

  initBasketManager () {
    this.basketManager = new BasketManager(this)
  }

  initCatalog() {
    $('.catalog-page').each((idx, el) => new Catalog($(el)))
  }

  initCatalogProduct() {
    $('.detail-page').each((idx, el) => new CatalogProduct($(el), this.basketManager, this))
  }

  initHeaderBasket() {
    this.headerBasket = null

    $('.js-header-basket').each(
      (idx, el) =>
        (this.headerBasket = new HeaderBasket($(el), this.basketManager, this))
    )
  }

  initCheckout() {
    $('.js-checkout').each(
      (idx, el) => (this.order = new Checkout($(el), this.basketManager, this))
    )
  }

  initNum () {
    $('.num').each((idx, el) => new Num($(el)))
  }
}
