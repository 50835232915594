import Swiper from 'swiper'
import { Autoplay, Pagination } from 'swiper/modules'

if ($('.js-productSwiper').length > 0) {
  new Swiper('.js-productSwiper', {
    modules: [Autoplay, Pagination],
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  })
}
