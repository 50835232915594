import Swiper from 'swiper'

if ($('.js-catalogSizeSlider').length > 0) {
  $('.js-catalogSizeSlider .swiper-wrapper').append(
    $('.js-catalogSizeSlider .swiper-slide').clone(),
  )
}

function initSliders() {
  if ($('.js-catalogSizeSlider').length > 0) {
    $('.js-catalogSizeSlider').each((_, el) => {
      const swiper = $(el).get(0)
      const initialSlide = $(el).attr('data-initial-slide')

      new Swiper(swiper, {
        slidesPerView: 5,
        spaceBetween: 0,
        initialSlide,
        centeredSlides: true,
        loop: true,
        slideToClickedSlide: true,
        observer: true,
        observeParents: true,
      })
    })
  }
}

window.updateCatalogSizeSliderSlider = () => {
  initSliders()
}
