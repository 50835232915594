$('.js-authButton').on('click', (e) => {
  e.preventDefault()
  $('.auth-aside').addClass('is_opened')

  $('body').addClass('body-lock')
})

$('.js-authAsideClose').on('click', () => {
  $('.auth-aside').removeClass('is_opened')

  $('body').removeClass('body-lock')
})

$('[data-auth-form-url]').on('click', (e) => {
  const currentTarget = $(e.currentTarget)
  const currentAuthUrl = currentTarget.attr('data-auth-form-url')
  const currentForm = $(`[data-auth-form-step="${currentAuthUrl}"]`)

  $('[data-auth-form-step]').hide()
  currentForm.show()

  $('.auth-aside').scrollTop(0)
})

$('.js-loginVartiantButton').on('click', (e) => {
  const variant = $(e.currentTarget).data('variant')

  $(e.currentTarget).addClass('is_active').siblings().removeClass('is_active')
  $(`[data-login-variant="${variant}"]`).show().siblings().hide()
})
