/* eslint-disable */
import { renderMap } from './render-map'
const mapComponent = document.querySelector('#js-map')

if (mapComponent) {
  const { filters, config, data } = window.mapParams

  const ref = {
    map: null,
    activeFilter: config.activeFilter,
    data: data,
    config: config,
    filters: filters,
    mapElement: mapComponent,
  }

  mapComponent.innerHTML = `
      <div class="map">
        ${renderMap({ ref })}
      </div>
    `
}
